import stl from "../../HomePage.module.css";
import LinksToSearch from "../LinksToSearch/LinksToSearch";
import {createInternalUrlWithParams} from "../../../../utils/common";
import {useLang} from "../../../../common/Contexts/LanguageContext";
import {useTranslation} from "@i18n";

export default function SearchByRangeContainer(props) {
    const lang = useLang();
    const { t } = useTranslation();
    const {
        price_ranges,
        odometer_ranges,
    } = props.data;
    return (<>
        <div
            className={[
                "m-show",
                stl.CustomContainer,
                stl.undoCustomWidth,
            ].join(" ")}>
            <LinksToSearch
                moreLink={createInternalUrlWithParams(
                    "/filters",
                    {
                        is_new: props.isNew,
                        sort: "price",
                    },
                    lang
                )}
                t={t}
                range
                bgColor="#EFF6FF"
                data={price_ranges}
                fieldName={"price"}
                linkValue="value"
                fromField="from"
                toField="to"
                language={lang}
                isNew={props.isNew}
                isPrice
            />
        </div>


        {!props.isNew && (
            <div
                className={[
                    "m-show",
                    stl.CustomContainer,
                    stl.undoCustomWidth,
                ].join(" ")}>
                <LinksToSearch
                    moreLink={createInternalUrlWithParams(
                        "/filters",
                        {
                            is_new: props.isNew,
                            sort: "milage",
                        },
                        lang
                    )}
                    t={t}
                    bgColor="#FCEDE3"
                    data={odometer_ranges }
                    fieldName={"range"}
                    linkValue={"id"}
                    language={lang}
                    isNew={props.isNew}
                    range={true}
                />
            </div>
        )}


    </>);

}