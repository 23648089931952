import { Link } from "react-router-dom";
import { CDN_LINK } from "@globalConfig";
import { createInternalUrlWithParams } from "../../../../utils/common";
import stl from "./LinksToSearch.module.css";
import { useLang } from "../../../../common/Contexts/LanguageContext";
import {useTranslation} from "@i18n"
import ProgressiveImage from "@componentsShared/ProgressiveImage";

const createLinkRaw = (props, lang)=>(item) => {
    if (props.isPrice)
        return `${createInternalUrlWithParams(
            "/filters",
            {
                ["condition_id" ]: props.isNew ? 1 : 0,
                ["sellingprice_from" ]: item.from,
                ["sellingprice_to" ]: item.to,
            },
        lang,
        {
          referral_location: "Home",
          browse_variant: props.isPrice ? "price" : "mileage",
          variant_value: `from ${item.from} to ${item.to}`,
        }
        )}`;


    return `${createInternalUrlWithParams(
        "/filters",
        {
            condition_id: props.isNew ? 1 : 0,
            odometer_from: item?.from?.replace(",", ""),
            odometer_to: item.to?.replace(",", ""),
        },
        lang,
        {
          referral_location: "Home",
          browse_variant: props.isPrice ? "price" : "mileage",
          variant_value: `from ${item.from} to ${item.to}`,
        }
    )}`;

};

function LinksToSearch(props) {
  const {t} = useTranslation(["web_home"]);
  const lang = useLang();
  const createLink = createLinkRaw(props,lang);

  return (
    <>
      <div className={stl.container}>
        {props?.data?.map((item, idx) => {
          return (
            <Link
              style={{ backgroundColor: props.bgColor }}
              key={"link" + idx}
              to={createLink(item)}>
              {`${item.text ? item.text : ""} ${item[props.fieldName]} ${
                item.unit
              }`}
            </Link>
          );
        })}
        <Link
          to={props.moreLink}
          className={stl.moreLink}
        >
          <span>
            {t("إعرض الكل", {
              ns: "web_home",
            })}
          </span>
          <ProgressiveImage
            waitUntilInteractive
            src={CDN_LINK + "/assets/images/arrMore.png"}
            fetchpriority="low"
            alt=""
            width={15}
            height={19}
          />
        </Link>
      </div>
    </>
  );
}

export default LinksToSearch;
